<template>
    <div>
        <form v-on:submit.prevent="login">
            <div class="text-2xl uppercase mb-4">Login</div>

            <div class="p-field mb-4">
                <label class="block text-600 mb-2">E-Mail-Adresse</label>
                <InputText
                    class="w-full"
                    type="email"
                    required
                    v-model="email"
                />
                <small class="p-invalid block mt-2" v-if="error === true">
                    Für diese Daten wurde kein Benutzer gefunden.
                </small>
            </div>

            <div class="p-field mb-4">
                <label class="block text-600 mb-2">
                    Passwort
                </label>
                <InputText
                    class="w-full"
                    type="password"
                    required
                    v-model="password"
                />
            </div>
            <Button label="Login" class="w-full" type="submit" />
        </form>
    </div>
</template>

<script>
    import auth from './../../plugins/auth';

    export default {
        mounted() {
            auth.clearAuth();
            this.$toast.add({
                severity: 'success',
                summary: 'Erfolgreich',
                detail: 'Sie haben sich erfolgreich ausgeloggt.',
                life: 3000
            });
            this.$router.push('/login');
        }
    }
</script>
